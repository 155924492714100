
<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Chatbots SMS</h3>
          </CCardHeader>
          <CCardBody>
            <div class="container">
              <div class="row">
                <div class="col-12 text-center">
                  <form
                    action="https://contacto-virtual.com/multiagente/public/loginexternal"
                    method="get"
                    target="_blank"
                  >
                    <input type="hidden" name="login" :value="emailUser" />
                    <input
                      type="hidden"
                      name="token"
                      :value="tokenExternalMua"
                    />
                    <CButton
                      color="info"
                      shape="rounded-pill"
                      size="lg"
                      type="submit"
                    >
                      Crear chatbot con Designer
                    </CButton>
                  </form>
                </div>
                <div class="col-12">
                  <hr />
                  <img :src="imagenDesigner" width="100%" />
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
export default {
  name: "Designer",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    imagenDesigner() {
      return `${this.$apiAdress}/dist/img/designer_ayuda.jpg`;
    },
    emailUser() {
      return this.$store.state.auth.user.email;
    },
    tokenExternalMua() {
      return this.$store.state.auth.user.token_external_mua;
    },
  },
};
</script>

